.ProjectsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #070712;
}

.ProjectsTitle {
  color: #ffffff;
  font-size: 80px;
}

.ProjectsGallery {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  gap: 40px;
  width: 80vw;
}

.GalleryImageWide {
  width: 20vw;
  height: 10vw;
  border: solid 2px #0044ff;
}

.GalleryImagePoster {
  width: 20vw;
  height: 30vw;
  border: solid 2px #0044ff;
}

.GalleryImageSquare {
  width: 20vw;
  height: 20vw;
  border: solid 2px #0044ff;
}

@media screen and (max-width: 768px) {
  .ProjectsTitle {
    font-size: 8vw;
    text-align: center;
  }
  .ProjectsGallery {
    width: 90%;
  }
  .GalleryImageWide {
    width: 100%;
    height: 100%;
  }
  .GalleryImageSquare {
    width: 100%;
    height: 100%;
  }
  .GalleryImagePoster {
    width: 100%;
    height: 100%;
  }
}
