.ContactContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #070712;
  height: 100%;
}
.ContactTitle {
  color: #ffffff;
  font-weight: 900;
  font-size: 3vw;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 80vw;
}

.SocialButton {
  flex-direction: row;
  justify-content: center;
  display: flex;
  padding: 1vw 2vw;
  background: linear-gradient(to right, #0044ff, #ffffff);
  margin-bottom: 0.8vw;
  color: #ffffff;
  border: solid 2px #0044ff;
  width: 32vw;
  cursor: pointer;
  background-size: 200%;
  transition: background-position 0.5s ease, color 0.5s ease;
}

.SocialButton:hover {
  background-position: right;
  color: #0044ff;
}

.ButtonIcon {
  width: 2vw;
  height: 2vw;
  margin-right: 0.6vw;
}

.ButtonText {
  margin-top: 0.4vw;
  margin-bottom: 0px;
}

@media screen and (max-width: 768px) {
  .ContactTitle {
    font-size: 4vw;
  }
  .SocialButton {
    width: 80vw;
    margin-top: 12px;
  }
  .ButtonText {
    font-size: 4vw;
  }
  .ButtonIcon {
    width: 6vw;
    height: 6vw;
    margin-right: 2vw;
  }
  .ContactContainer {
    height: 220vw;
  }
}
