.HomeContainer {
  display: flex;
  flex-direction: column;
  background: #070712;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.SideMenu {
  display: flex;
}

.HomeTitle {
  color: #ffffff;
  font-size: 80px;
  text-align: center;
  width: 88vw;
  font-style: italic;
  font-weight: 900;
}

.ImageSwiper {
  width: 100%;
  height: 48vw;
  margin-top: 2vw;
  overflow: hidden;
}

div.swiper-pagination {
  display: flex;
  margin-top: -80px;
}

div.swiper-button-prev {
  margin-top: -80px !important;
  background: #05050d;
  padding: 12px 16px;
  border-radius: 8px;
}

div.swiper-button-next {
  margin-top: -80px !important;
  background: #05050d;
  padding: 12px 16px;
  border-radius: 8px;
}

.SwiperImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.HomeContent {
  display: flex;
  flex-direction: column;
  background: #05050d;
  width: 100%;
  justify-content: center;
  align-items: center !important;
}

.MagicPhoto {
}

.HomeContent > a:nth-child(4) .DynamicWrapper div,
.HomeContent > a:nth-child(6) .DynamicWrapper div {
  order: 2;
  text-align: right;
}

.HomeContent > a:nth-child(4) .DynamicWrapper div .DynamicTitle {
  justify-content: right;
}

.HomeContent > a:nth-child(4) .DynamicWrapper img,
.HomeContent > a:nth-child(6) .DynamicWrapper img {
  order: 1;
}

.HomeFooter {
  background: #070712;
  height: 100px;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.FooterLogo {
}

.FooterSign {
}

.FooterSocials {
}

.FooterContactImg {
  height: 40px;
  width: 40px;
  margin-right: 8px;
  height: auto; /* Garante que a proporção da imagem seja mantida */
  transition: transform 0.3s ease; /* Adiciona uma transição suave */
}

.FooterContactImg:hover {
  transform: scale(1.1); /* Amplia a imagem ao passar o mouse */
  z-index: 1; /* Garante que a imagem fique sobre outros elementos */
}

@media screen and (max-width: 768px) {
  .swiper {
    border: none;
    width: 100%;
  }

  .ImageSwiper {
    margin-top: 4vw;
  }

  div.swiper-button-prev {
    width: 2vw;
    height: 4vw;
    border-radius: 4px;
    margin-top: -6vw !important;
  }

  div.swiper-button-prev::after,
  div.swiper-button-next::after {
    font-size: 4vw;
    font-weight: 800;
  }

  div.swiper-button-next {
    width: 2vw;
    height: 4vw;
    border-radius: 4px;
    margin-top: -6vw !important;
  }

  .HomeTitle {
    font-size: 5vw;
    width: 95%;
  }

  .ProjectLabel {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .HomeContent > a:nth-child(4) .DynamicWrapper div,
  .HomeContent > a:nth-child(6) .DynamicWrapper div {
    order: 1;
    text-align: center;
  }

  .HomeContent > a:nth-child(4) .DynamicWrapper div .DynamicTitle {
    justify-content: center;
  }

  .HomeContent > a:nth-child(4) .DynamicWrapper img,
  .HomeContent > a:nth-child(6) .DynamicWrapper img {
    order: 2;
  }
}
