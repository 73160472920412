.NavBarContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #22223c;
  width: 100%;
}

.Signature {
  width: 32vw;
  height: 2vw;
  margin-left: 1vw;
}

.NavigationButtons {
  margin-right: 1vw;
  display: flex;
  flex-direction: row;
  gap: 1.5vw;
}

.LinkToPage {
}

.LinkLabel {
  color: #ffffff;
}

.LinkLabel:hover {
  color: #0044ff;
}

@media screen and (max-width: 768px) {
  .NavBarContainer {
    justify-content: center;
  }

  .NavigationButtons {
    gap: 8vw;
  }
  .NavBarContainer {
    height: 12vw;
  }

  .Signature {
    display: none;
  }

  .LinkLabel {
    font-size: 4vw;
  }
}
